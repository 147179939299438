<template>
  <div class="search-wrapper">
    <div class="search-input-wrapper">
      <input class="search" type="text" v-model="searchQuery" id="filter-text-box" placeholder="Search..." @change="change">
      <label for="filter-text-box" class="search-input-wrapper__label">Search...</label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'header-search',
  data() {
    return {
      search: null
    }
  },
  computed: {
    searchQuery: {
      get() {
        return this.$store.getters.getSearchQuery(this.$route.name)
      },
      set(value) {
        this.$store.dispatch('setSearchQuery', { page: this.$route.name, query: value })
      }
    }
  },
  mounted() {
    this.checkFilledSearch()
    this.$emitter.on('header_reset_filters', () => {
      this.searchQuery = ''
    })
  },
  beforeDestroy() {
    this.$emitter.off('header_reset_filters')
  },
  methods: {
    change() {
      this.$emitter.emit('header_addons_changed')
      this.$emitter.emit('header_search_addon_changed')
    },
    checkFilledSearch() {
      const input = document.querySelector('.search-input-wrapper input')
      if (!input) {
        return false
      }
      const inputEvent = () => {
        if (input.value) {
          input.classList.add('filled')
        } else {
          input.classList.remove('filled')
        }
      }
      input.addEventListener('input', inputEvent)
      setTimeout(() => {input.dispatchEvent(new Event('input'))}, 1)
    }
  }
}
</script>
